<template>
  <div class="home">
    <!-- <van-button type="primary" @click="toList">全部商品</van-button> -->
    <van-search
      v-model="poductName"
      placeholder="请输入商品名称"
      @click="search"
    />
    <div class="tabs-box">
      <!-- <div class="content">
        <div
          :class="activeTab == item.id ? 'tab-item active' : 'tab-item'"
          v-for="item in tabList"
          :key="item.id"
          @click="changeTab(item.id)"
        >
          <span class="tab-name">{{ item.cateName }}</span>
          <span class="line"></span>
        </div>
      </div> -->
      <van-tabs v-model="activeTab" @click="changeTab">
        <van-tab
          v-for="item in tabList"
          :key="item.id"
          :title="item.cateName"
          :name="item.id"
        ></van-tab>
      </van-tabs>
      <div class="wap-nav" @click="searchFun">
        <!-- <div class="wap-border"></div> -->
        <van-icon name="wap-nav" />
      </div>
    </div>
    <!-- <div class="tabs-box">
      <div class="content">
        <div
          :class="activeTab2 == item.id ? 'tab-item2 active' : 'tab-item2'"
          v-for="item in tabList2"
          :key="item.id"
          @click="changeTab2(item.id)"
        >
          <span class="tab-name">{{ item.name }}</span>
          <span v-if="index == 2" class="caret-wrapper">
            <i
              class="sort-caret ascending"
              :style="{
                borderBottomColor: sortType == 'top' ? '#409eff' : '#c0c4cc',
              }"
              @click="sorthandle('top')"
            ></i>
            <i
              class="sort-caret descending"
              :style="{
                borderTopColor: sortType == 'bottom' ? '#409eff' : '#c0c4cc',
              }"
              @click="sorthandle('bottom')"
            ></i>
          </span>
        </div>
      </div>
    </div> -->
    <div class="good-list-box">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-if="goodsList && goodsList.length > 0"
          v-model="loading"
          :finished="finished"
          :loading-text="loadingText"
          :finished-text="finishedText"
          @load="getDataList"
        >
          <product-list :data="goodsList" />
          <!-- <water-list :data="goodsList" /> -->
        </van-list>
        <div v-else class="no-list">
          <div class="no-img">
            <img src="../assets/images/no-list.png" alt="" />
          </div>
          <div class="no-tip">抱歉，当前搜索项暂无商品</div>
        </div>
      </van-pull-refresh>
    </div>
    <search-select ref="searchSelect" @changeTabSearch="changeTabSearch" />
  </div>
</template>

<script>
import { Button, Icon, Search, List, PullRefresh } from "vant";
import { mapMutations } from "vuex";
import DigitalGoods from "../components/DigitalGoods.vue";
import { getCateList, digitalproductpage } from "../api/common";
import { mapState } from "vuex";
import ProductList from "@/components/ProductList.vue";
import WaterList from "@/components/WaterList.vue";
import SearchSelect from "@/components/SearchSelect.vue";

export default {
  name: "Goods",
  computed: {
    ...mapState(["poductName"]),
  },
  data() {
    return {
      value: "",
      activeTab: "all",
      activeTab2: 0,
      sortType: "",
      goodList: [],
      tabList: [],
      tabList2: [
        {
          id: 0,
          name: "综合推荐",
        },
        {
          id: 1,
          name: "销量排行",
        },
      ],
      refreshing: false,
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      finished: false,
      loadingText: "",
      finishedText: "我也是有底线的~",
      goodsList: [],
    };
  },
  components: {
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Search.name]: Search,
    DigitalGoods,
    ProductList,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    WaterList,
    SearchSelect,
  },
  mounted() {
    this.activeTab = this.$route.query?.id || "all";
    this.$nextTick(() => {
      this.getList();
    });
  },
  methods: {
    ...mapMutations(["setProductName"]),

    // 商品分类
    async productCate() {
      const res = await getCateList();
      if (res && res.code == 0) {
        console.log(res);
        let data = res.data.list || [];
        this.tabList = data.filter((item) => item.cateType != 20);
        this.tabList.unshift({
          id: "all",
          cateName: "全部",
        });
      } else {
        this.$message.error(res.message);
      }
    },
    toList() {
      this.$router.push("/mall/orderList");
    },
    // 切换tba
    changeTab(activeTab) {
      // if (this.activeTab == activeTab) return;
      this.activeTab = activeTab;
      this.$nextTick(() => {
        this.getList();
      });
    },
    search() {
      this.$router.push("/mall/SearchGood");
    },

    // 积分的排序
    sorthandle(type) {
      if (this.sortType == type) return;
      this.sortType = type;
    },

    getList() {
      this.pageIndex = 1;
      this.$nextTick(async () => {
        try {
          const res = await digitalproductpage({
            pageSize: this.pageSize,
            pageIndex: this.pageIndex,
            CateId: this.activeTab == "all" ? "" : this.activeTab,
            ProductName: this.poductName || "",
            ProductStatus: 10,
          });
          if (res && res.code == 0) {
            this.goodsList = res.data.list || [];
            if (res.data.total === this.goodsList.length) {
              this.finished = true;
            } else {
              this.finished = false;
            }
            this.pageIndex = 2;
            this.refreshing = false;
            this.loading = false;
            // this.finished = true;
          } else {
            this.$toast(res.message);
            this.loading = false;
            this.finished = true;
          }
        } catch (error) {
          this.loading = false;
          this.finished = true;
        }
      });
    },

    // 获取商品列表
    async getDataList(type) {
      try {
        const res = await digitalproductpage({
          pageSize: this.pageSize,
          pageIndex: this.pageIndex,
          CateId: this.activeTab == "all" ? "" : this.activeTab,
          ProductName: this.poductName || "",
        });
        if (res && res.code == 0) {
          this.goodsList = [...this.goodsList, ...(res.data.list || [])];
          this.$nextTick(() => {
            if (this.goodsList.length < res.data.total) {
              this.loading = false;
              this.pageIndex++;
            } else {
              this.finished = true;
              this.loading = true;
            }
          });
        } else {
          this.$toast(res.message);
          this.loadingText = res.message;
          this.finished = true;
        }
      } catch (error) {
        this.finished = true;
        this.loading = true;
      }
    },

    // 下拉刷新
    onRefresh() {
      this.getList();
    },

    // 下拉展开 商品分类
    searchFun() {
      this.$refs.searchSelect.searchFun();
    },
    changeTabSearch(val) {
      this.activeTab = val;
      this.$refs.searchSelect.close();
      this.$nextTick(() => {
        this.getList();
      });
    },
  },
  created() {
    document.title = "全部商品";
    this.productCate();
  },
  destroyed() {
    this.setProductName("");
  },
};
</script>

<style lang="less" scoped>
.home {
  height: 100%;
  display: flex;
  flex-direction: column;
  /deep/ .van-search {
    padding-bottom: 0;
  }

  /deep/ .van-tabs__line {
    background-color: #ff503f !important;
  }

  ::-webkit-scrollbar {
    display: none;
  }
  .tabs-box {
    width: 100%;
    height: 0.88rem;
    overflow: hidden;
    padding: 0 0.6rem 0 0.2rem;
    box-sizing: border-box;
    // overflow-x: scroll;
    // display: flex;
    // align-items: center;
    background-color: #fff;
    position: relative;

    .content {
      display: flex;

      .tab-item {
        width: 1.74rem;
        padding: 0 0.2rem;
        font-size: 0.3rem;
        font-family: Helvetica;
        font-weight: 400;
        color: #9b9b9b;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &.active {
          color: #000;
          font-family: Helvetica;
        }

        .line {
          width: 0.26rem;
          height: 0.08rem;
          background: #fff;
          border-radius: 0.05rem;
          margin-top: 0.08rem;
        }
      }
      .tab-item.active .line {
        background: #ff684a;
      }
      .tab-item2 {
        font-size: 0.24rem;
        width: 1.74rem;
        padding: 0 0.2rem;
        font-weight: 400;
        color: #9b9b9b;
        display: flex;
        align-items: center;
        justify-content: center;

        &.active {
          color: #000;
          font-family: Helvetica;
          font-weight: 400;
          font-size: 0.25rem;
        }

        .caret-wrapper {
          height: 0.68rem;
          width: 0.48rem;
          cursor: pointer;
          position: relative;
          .sort-caret {
            width: 0;
            height: 0;
            border: 0.1rem solid transparent;
            position: absolute;
            left: 0.14rem;
          }
          .sort-caret.ascending {
            border-bottom-color: #c0c4cc;
            top: 0.1rem;
          }

          .sort-caret.descending {
            border-top-color: #c0c4cc;
            bottom: 0.14rem;
          }
        }
      }
    }

    .wap-nav {
      // .wap-border {
      //   width: 0.04rem;
      //   height: 0.4rem;
      //   background: #d8d8d8;
      //   opacity: 0.5;
      //   filter: blur(0.02rem);
      //   padding-right: 0.04rem;
      //   margin-right: 0.1rem;
      // }

      position: absolute;
      top: 0;
      right: 0;
      width: 0.8rem;
      height: 0.82rem;
      font-size: 0.3rem;
      color: #868686;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .good-list-box {
    flex: 1;
    padding: 0.2rem 0.2rem 0;
    padding-bottom: 0;
    box-sizing: border-box;
    background: #f9f9f9;
    height: 100%;
    overflow-y: auto;
    // border-top: 0.2rem solid rgba(255, 255, 255, 0);
  }

  /deep/ .van-pull-refresh {
    // overflow-y: auto;
    // height: calc(100vh - 1rem);
  }

  .no-list {
    width: 100%;
    height: 100%;
    background: #f9f9f9;
    display: flex;
    flex-direction: column;
    align-items: center;

    .no-img {
      width: 1.96rem;
      height: 1.96rem;
      border-radius: 50%;
      overflow: hidden;
      background-color: #ccc;
      margin-top: 30%;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .no-tip {
      font-size: 0.24rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      text-align: center;
      margin-top: 0.32rem;
    }
  }
}
</style>
