<template>
  <van-popup
    v-model="searchShow"
    round
    :close-on-click-overlay="true"
    position="top"
    :style="{ height: '45%' }"
  >
    <div class="heard">
      <div class="searchSelect_title">全部类型</div>
      <div class="searchSelect_icon" @click="close">
        <van-icon name="cross" />
      </div>
    </div>
    <div class="list">
      <div
        :class="activeTab == item.id ? 'list-item active' : 'list-item'"
        @click="changeTab(item.id)"
        v-for="item in tabList"
        :key="item.id"
      >
        {{ item.cateName }}
      </div>
    </div>
  </van-popup>
</template>

<script>
import { Icon, Popup } from "vant";
import { getCateList } from "../api/common";
export default {
  name: "SearchSelect",
  data() {
    return {
      searchShow: false,
      tabList: [],
      activeTab: "all",
    };
  },
  components: {
    [Popup.name]: Popup,
    [Icon.name]: Icon,
  },
  created() {
    this.productCate();
  },
  methods: {
    // 商品分类
    async productCate() {
      const res = await getCateList();
      if (res && res.code == 0) {
        console.log(res);
        let data = res.data.list || [];
        this.tabList = data.filter((item) => item.cateType != 20);
        this.tabList.unshift({
          id: "all",
          cateName: "全部",
        });
      } else {
        this.$message.error(res.message);
      }
    },

    searchFun() {
      this.searchShow = true;
    },
    close() {
      this.searchShow = false;
    },

    changeTab(activeTab) {
      if (this.activeTab == activeTab) return;
      this.activeTab = activeTab;
      this.$emit('changeTabSearch', activeTab)
    },
  },
};
</script>

<style lang="less" scoped>
.heard {
  display: flex;
  justify-content: space-between;
  height: 0.8rem;
  padding: 0 0.4rem;
  margin-top: 0.26rem;
  .searchSelect_title {
    font-size: 0.28rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
  }
  .searchSelect_icon {
    font-size: 0.3rem;
    color: #b3b3b3;
  }
}
.list {
  height: 4.6rem;
  padding: 0 0.1rem 0 0.4rem;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;

  .list-item {
    width: 30%;
    height: 0.68rem;
    border-radius: 0.08rem;
    font-size: 0.24rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 500;
    background: #f8f8f7;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.18rem;
    margin-bottom: 0.16rem;
  }
  .list-item:last-child {
    margin-right: 0;
  }

  .active {
    background: #fdf0f1;
    color: #ff686e;
    border: 0.02px solid #f5a8ac;
  }
}
</style>